import { Route } from '@angular/router';

import { Applikation } from '@mp/shared/data-access';

import { moduleImports } from './module-imports';

export class RouteBuilder {
  private errorRoute?: Route;

  private constructor(private readonly routes: Array<Route>) {}

  static fromRouterConfig(routerConfig: Array<Route>): RouteBuilder {
    return new RouteBuilder([...routerConfig]);
  }

  registerErrorRoute(errorRoute: Route): RouteBuilder {
    this.errorRoute = errorRoute;

    return this;
  }

  registerMultipleApplications(apps: Array<Applikation>): RouteBuilder {
    apps.forEach((app) => this.registerSingleApplication(app));

    return this;
  }

  registerSingleApplication(app: Applikation): RouteBuilder {
    if (app.childApplications.length > 0) {
      this.registerGroupRoutes(app, this.routes);
    } else {
      this.registerSingleRoute(app, this.routes);
    }

    return this;
  }

  buildRoutes(): Array<Route> {
    this.appendErrorRouteDefinitions();

    return this.routes;
  }

  private appendErrorRouteDefinitions(): void {
    if (this.errorRoute) {
      this.routes.push(this.errorRoute);
      this.routes.push({
        path: '**',
        redirectTo: this.errorRoute.path,
      });
    }
  }

  private registerGroupRoutes(app: Applikation, appRoutes: Array<Route>): void {
    const parentRoute = this.createParentRouteFromApplication(app);
    appRoutes.push(parentRoute);

    app.childApplications.forEach((childApp: Applikation) => {
      const moduleExistsForApplication = !!moduleImports[childApp.name];
      if (moduleExistsForApplication) {
        const childRoute = this.createChildRouteFromApplications(app, childApp);
        appRoutes.push(childRoute);

        if (childRoute.path) {
          childApp.route = childRoute.path;
        }
      }
    });
  }

  private createParentRouteFromApplication(app: Applikation): Route {
    return {
      path: app.route,
      pathMatch: 'full',
      redirectTo: `${app.route}/${app.childApplications[0].route}`,
    };
  }

  private createChildRouteFromApplications(parentApp: Applikation, childApp: Applikation): Route {
    return {
      path: `${parentApp.route}/${childApp.route}`,
      loadChildren: moduleImports[childApp.name],
    };
  }

  private registerSingleRoute(app: Applikation, appRoutes: Array<Route>): void {
    const moduleExistsForApplication = !!moduleImports[app.name];
    if (moduleExistsForApplication) {
      const route = this.createStandaloneRouteFromApplication(app);
      appRoutes.push(route);
    }
  }

  private createStandaloneRouteFromApplication(app: Applikation): Route {
    return {
      path: app.route,
      loadChildren: moduleImports[app.name],
    };
  }
}
