import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import {
  AnforderungenHistorieWidgetData,
  AnforderungenOffenWidgetData,
  DashboardService,
  MedicalShopKennzahlenDto,
  MeineOrganisationWidgetData,
} from '@mp/dashboard/data-access';
import { NewsfeedComponent } from '@mp/dashboard/newsfeed/feature';
import { AnforderungenService } from '@mp/medical-shop/auftraege/data-access';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { ContentManagementDashboardModule, DatasourceUploadWidgetModule } from '@mpcm/shared';
import { DocumentManagementDashboardModule, HasRequisitionsRightsPipe } from '@mpdm/shared';
import { MedicalShopDashboardModule } from '@mpms/shared';

import { DashboardFacade } from './dashboard.facade';
import { HasRequisitionsWidgetRightsPipe } from './has-requisitions-widget-rights.pipe';
import { AnforderungenHistorieWidgetComponent } from './widgets/anforderungen-historie/anforderungen-historie-widget.component';
import { AnforderungenOffenWidgetComponent } from './widgets/anforderungen-offen/anforderungen-offen-widget.component';
import { MedicalShopWidgetComponent } from './widgets/medical-shop/medical-shop-widget.component';
import { MeineOrganisationWidgetComponent } from './widgets/meine-organisation/meine-organisation-widget.component';

@Component({
  selector: 'mp-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,

    MatIconModule,

    UserInfoModule,
    DatasourceUploadWidgetModule,
    DashboardWidgetComponent,
    ContentManagementDashboardModule,
    DocumentManagementDashboardModule,
    MedicalShopDashboardModule,
    HasRequisitionsWidgetRightsPipe,
    AnforderungenHistorieWidgetComponent,
    AnforderungenOffenWidgetComponent,
    MedicalShopWidgetComponent,
    MeineOrganisationWidgetComponent,
    NewsfeedComponent,
  ],
  providers: [AnforderungenService, DashboardFacade, DashboardService, HasRequisitionsRightsPipe],
})
export class DashboardComponent {
  @HostBinding('class') readonly class = 'mp-dashboard';

  medicalShopWidgetData$: Observable<MedicalShopKennzahlenDto | undefined> = this.facade.medicalShopWidgetData$;
  meineOrganisationWidgetData$: Observable<MeineOrganisationWidgetData | undefined> =
    this.facade.meineOrganisationWidgetData$;
  anforderungenOffenWidgetData$: Observable<AnforderungenOffenWidgetData | undefined> =
    this.facade.anforderungenOffenWidgetData$;
  anforderungenHistorieWidgetData$: Observable<AnforderungenHistorieWidgetData | undefined> =
    this.facade.anforderungenHistorieWidgetData$;
  anforderungenGesamtwertData$: Observable<number | undefined> = this.facade.anforderungenGesamtwertData$;

  readonly readRequisitionsRights: RechtFilter = {
    resource: Resources.Anforderungen,
    action: Actions.Read,
  } as const;
  readonly readAllRequisitionsRights: RechtFilter = {
    resource: Resources.AlleAnforderungen,
    action: Actions.Read,
  } as const;
  readonly readOrganizationRights: RechtFilter = {
    resource: Resources.MyOrganisationWidget,
    action: Actions.Read,
  } as const;
  readonly medicalShopWriteRights: RechtFilter = {
    resource: Resources.Carts,
    action: Actions.Write,
  } as const;

  readonly showContentManagementWidgetRight: RechtFilter = {
    resource: Resources.ContentManagementArticle,
    action: Actions.Read,
  } as const;
  readonly showDatasourceUploadWidgetRight: RechtFilter = {
    resource: Resources.FileUploadCM,
    action: Actions.Use,
  };
  readonly showMedicalShopWidgetRight: RechtFilter = {
    resource: Resources.MedicalShopWidget,
    action: Actions.Show,
  } as const;
  readonly showPendingRequisitionsWidgetRight: RechtFilter = {
    resource: Resources.PendingRequisitionsWidget,
    action: Actions.Show,
  } as const;
  readonly showRequisitionsHistoryWidgetRight: RechtFilter = {
    resource: Resources.RequisitionsHistoryWidget,
    action: Actions.Show,
  } as const;

  constructor(private readonly facade: DashboardFacade) {
    this.facade.loadAllWidgetData();
  }
}
