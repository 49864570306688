import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PaginationMetadata } from '@mp/shared/data-access';
import { ListNewsItem, OrganisationBasic } from '@mp/shared/kernel/newsfeed/domain';

import { NewsfeedActions } from './newsfeed.actions';

export const newsfeedFeatureKey = 'mp-newsfeed';

type NewsfeedEntityState = EntityState<ListNewsItem>;

export const newsItemEntityAdapter: EntityAdapter<ListNewsItem> = createEntityAdapter<ListNewsItem>({
  selectId: (newsItem: ListNewsItem) => newsItem.newsItemId,
});

export interface NewsfeedState {
  newsList: NewsfeedEntityState;
  isNewsListLoading: boolean;
  newsListPaginationMetadata: PaginationMetadata | undefined;
  availableOrganisations: OrganisationBasic[];
}

export const initialState: NewsfeedState = {
  newsList: newsItemEntityAdapter.getInitialState(),
  isNewsListLoading: false,
  newsListPaginationMetadata: undefined,
  availableOrganisations: [],
};

export const newsfeedReducer = createReducer(
  initialState,
  on(
    NewsfeedActions.fetchNewsList,
    (state): NewsfeedState => ({
      ...state,
      newsList: newsItemEntityAdapter.getInitialState(),
      isNewsListLoading: true,
    }),
  ),
  on(
    NewsfeedActions.fetchNewsListSuccess,
    (state, { newsPageResponse }): NewsfeedState => ({
      ...state,
      newsList: newsItemEntityAdapter.setAll(newsPageResponse.data, state.newsList),
      newsListPaginationMetadata: newsPageResponse.pagination,
      isNewsListLoading: false,
    }),
  ),
  on(
    NewsfeedActions.fetchNewsListError,
    (state): NewsfeedState => ({
      ...state,
      newsList: newsItemEntityAdapter.getInitialState(),
      newsListPaginationMetadata: undefined,
      isNewsListLoading: false,
    }),
  ),
  on(
    NewsfeedActions.fetchAvailableOrganisations,
    NewsfeedActions.fetchAvailableOrganisationsError,
    (state): NewsfeedState => ({
      ...state,
      availableOrganisations: [],
    }),
  ),
  on(
    NewsfeedActions.fetchAvailableOrganisationsSuccess,
    (state, { availableOrganisations }): NewsfeedState => ({
      ...state,
      availableOrganisations,
    }),
  ),
  on(
    NewsfeedActions.updateNewsEntrySuccess,
    (state, { updatedNews }): NewsfeedState => ({
      ...state,
      newsList: newsItemEntityAdapter.updateOne({ id: updatedNews.newsItemId, changes: updatedNews }, state.newsList),
    }),
  ),
);

export const { selectAll: selectAllNewsItems } = newsItemEntityAdapter.getSelectors();
