import { Injectable } from '@angular/core';

import { NotificationService } from '@mp/shared/data-access';
import {
  ALL_SUPPORTED_FILE_TYPE_EXTENSIONS,
  getFileExtension,
} from '@mp/shared/helper-links/zendesk-support-dialog/util';

@Injectable()
export class SupportMessageAttachmentsPresenter {
  constructor(private readonly notificationService: NotificationService) {}

  validateAttachments(files: File[], maxFileSize: number, attachmentsRemainingLimit: number): boolean {
    const attachmentsLimitExceeded: boolean = files.length > attachmentsRemainingLimit;
    if (attachmentsLimitExceeded) {
      this.notificationService.toastDanger('Die Anzahl der erlaubten Anhänge wurde überschritten.');
      return false;
    }

    if (this.isAnyFileExceedingSizeLimit(files, maxFileSize)) {
      this.notificationService.toastDanger('Einige Dateien sind größer als erlaubt.');
      return false;
    }

    if (this.isAnyFileTypeExtensionUnsupported(files)) {
      this.notificationService.toastDanger('Einige Dateien haben ein nicht erlaubtes Format.');
      return false;
    }

    return true;
  }

  private isAnyFileExceedingSizeLimit(files: File[], maxFileSize: number): boolean {
    return files.some(({ size }) => size > maxFileSize);
  }

  private isAnyFileTypeExtensionUnsupported(files: File[]): boolean {
    return files
      .map(({ name }) => getFileExtension(name))
      .some((extension) => this.isUnsupportedFileTypeExtension(extension));
  }

  private isUnsupportedFileTypeExtension(fileExtension: string): boolean {
    return !ALL_SUPPORTED_FILE_TYPE_EXTENSIONS.includes(fileExtension);
  }
}
