import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MedicalShopKennzahlenDto } from './widget-data/medical-shop-widget-data';

@Injectable()
export class DashboardService {
  constructor(private readonly http: HttpClient) {}

  getStatistics(): Observable<MedicalShopKennzahlenDto> {
    return this.http.get<MedicalShopKennzahlenDto>('/api/kennzahlen/medicalshop/artikel');
  }

  getAnzahlBenutzer(): Observable<number> {
    return this.http.get<number>('/api/kennzahlen/my/organisation');
  }
}
