import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { getPaginationParamsAfterDeletion } from '@core/shared/util';
import { NotificationService, PaginationMetadata } from '@mp/shared/data-access';

import { NewsfeedService } from '../services';

import { NewsfeedActions } from './newsfeed.actions';
import { selectNewsListPaginationMetadata } from './newsfeed.selectors';

@Injectable()
export class NewsfeedEffects {
  fetchNewsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.fetchNewsList),
      fetch({
        run: ({ pagination }) =>
          this.newsfeedService
            .getNews(pagination)
            .pipe(map((news) => NewsfeedActions.fetchNewsListSuccess({ newsPageResponse: news }))),
        onError: () => NewsfeedActions.fetchNewsListError(),
      }),
    );
  });

  fetchAvailableOrganisations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.fetchAvailableOrganisations),
      fetch({
        run: () =>
          this.newsfeedService
            .getAvailableOrganisations()
            .pipe(
              map((availableOrganisations) =>
                NewsfeedActions.fetchAvailableOrganisationsSuccess({ availableOrganisations }),
              ),
            ),
        onError: () => NewsfeedActions.fetchAvailableOrganisationsError(),
      }),
    );
  });

  createNewsEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.createNewsEntry),
      fetch({
        run: ({ createNews }) =>
          this.newsfeedService.addNews(createNews).pipe(
            map((createdNews) => NewsfeedActions.createNewsEntrySuccess({ createdNews })),
            tap(() => this.notificationService.toastSuccess('Die Änderungen wurden gespeichert.')),
          ),
        onError: () => {
          this.notificationService.toastDanger(
            'Beim Speichern ist leider etwas schiefgegangen. Bitte versuche es nochmal.',
          );
          return NewsfeedActions.createNewsEntryError();
        },
      }),
    );
  });

  createNewsEntrySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.createNewsEntrySuccess),
      map(() => NewsfeedActions.fetchNewsList({})),
    );
  });

  updateNewsEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.updateNewsEntry),
      fetch({
        run: ({ newsItemId, updateNews }) =>
          this.newsfeedService.updateNews(newsItemId, updateNews).pipe(
            map((updatedNews) => NewsfeedActions.updateNewsEntrySuccess({ updatedNews })),
            tap(() => this.notificationService.toastSuccess('Die Änderungen wurden gespeichert.')),
          ),
        onError: () => {
          this.notificationService.toastDanger(
            'Beim Speichern ist leider etwas schiefgegangen. Bitte versuche es nochmal.',
          );
          return NewsfeedActions.updateNewsEntryError();
        },
      }),
    );
  });

  deleteNewsEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.deleteNewsEntry),
      fetch({
        run: ({ newsItemId }) =>
          this.newsfeedService.deleteNews(newsItemId).pipe(
            map(() => NewsfeedActions.deleteNewsEntrySuccess({ newsItemId })),
            tap(() => this.notificationService.toastSuccess('Die News wurde erfolgreich gelöscht.')),
          ),
        onError: () => {
          this.notificationService.toastDanger(
            'Beim Speichern ist leider etwas schiefgegangen. Bitte versuche es nochmal.',
          );
          return NewsfeedActions.deleteNewsEntryError();
        },
      }),
    );
  });

  deleteNewsEntrySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsfeedActions.deleteNewsEntrySuccess),
      concatLatestFrom(() => this.store$.select(selectNewsListPaginationMetadata)),
      map(([, paginationMetadata]) => getPaginationParamsAfterDeletion(paginationMetadata as PaginationMetadata)),
      map((pagination) => NewsfeedActions.fetchNewsList({ pagination: pagination })),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly newsfeedService: NewsfeedService,
    private readonly notificationService: NotificationService,
  ) {}
}
