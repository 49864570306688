import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, map, tap, throwError } from 'rxjs';

import {
  AttachmentUploadResponse,
  SearchHelpCenterArticlesResponse,
  SupportTicketRequest,
} from '@mp/shared/zendesk/domain';
import { ZendeskApiDetails, ZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { mapSearchHelpCenterArticlesResponse } from '../mappers';
import { SearchHelpCenterArticlesResponseRaw } from '../models';

@Injectable()
export class ZendeskService {
  private get baseUrl(): string {
    return `${this.zendeskApiDetails?.url}/api/v2`;
  }

  private httpBackend: HttpClient;

  private zendeskApiDetails: ZendeskApiDetails | null = null;

  private readonly HELP_CENTER_ARTICLES_PAGE_SIZE = 5;

  constructor(
    private readonly handler: HttpBackend,
    private readonly zendeskApiDetailsService: ZendeskApiDetailsService,
  ) {
    /**
     * Initialize a specialized HttpClient instance using the provided handler.
     * By doing so, we intentionally bypass any interceptors to ensure a direct
     * interaction with Zendesk, which mandates the exclusion of certain headers.
     */
    this.httpBackend = new HttpClient(this.handler);

    this.zendeskApiDetailsService.zendeskApiDetails$
      .pipe(
        tap((zendeskApiDetails: ZendeskApiDetails) => (this.zendeskApiDetails = zendeskApiDetails)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  /**
   * Create support ticket request.
   * @param supportTicketRequest Support ticket request.
   * @returns The create ticket request success response.
   */
  createTicket(supportTicketRequest: SupportTicketRequest): Observable<unknown> {
    if (this.hasInvalidZendeskApiDetails()) {
      return this.throwNoZendeskApiDetailsError();
    }

    return this.httpBackend.post<unknown>(`${this.baseUrl}/requests`, supportTicketRequest, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  /**
   * Uploads attachment.
   * @param file File to upload.
   * @returns Attachment upload response.
   */
  uploadAttachment(file: File): Observable<AttachmentUploadResponse> {
    if (this.hasInvalidZendeskApiDetails()) {
      return this.throwNoZendeskApiDetailsError();
    }

    return this.httpBackend.post<AttachmentUploadResponse>(`${this.baseUrl}/uploads`, file, {
      params: { filename: file.name },
      headers: this.getUploadHeaders(file.type),
    });
  }

  /**
   * Get help center articles which are conforming search criteria.
   * @returns Most relevant articles.
   */
  searchArticles(labelName: string): Observable<SearchHelpCenterArticlesResponse> {
    if (this.hasInvalidZendeskApiDetails()) {
      return this.throwNoZendeskApiDetailsError();
    }

    return this.httpBackend
      .get<SearchHelpCenterArticlesResponseRaw>(`${this.baseUrl}/help_center/articles/search`, {
        params: {
          // If no label is provided a ',' character is used to fetch all articles.
          label_names: labelName || ',',
          per_page: this.HELP_CENTER_ARTICLES_PAGE_SIZE,
        },
        headers: this.getAuthorizationHeaders(),
      })
      .pipe(
        map((rawResponse: SearchHelpCenterArticlesResponseRaw) => mapSearchHelpCenterArticlesResponse(rawResponse)),
      );
  }

  private getAuthorizationHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.zendeskApiDetails?.token}`);
  }

  private getUploadHeaders(fileContentType: string): HttpHeaders {
    const requestHeaders: HttpHeaders = this.getAuthorizationHeaders();
    return requestHeaders.set('Content-Type', fileContentType);
  }

  private hasInvalidZendeskApiDetails(): boolean {
    return !this.zendeskApiDetails?.url || !this.zendeskApiDetails?.token;
  }

  private throwNoZendeskApiDetailsError(): Observable<never> {
    return throwError(() => new Error('Zendesk API details not available'));
  }
}
