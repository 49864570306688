import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { AnforderungenOffenWidgetData } from '@mp/dashboard/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';

@Component({
  selector: 'mp-anforderungen-offen-widget',
  templateUrl: './anforderungen-offen-widget.component.html',
  styleUrls: ['./anforderungen-offen-widget.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, DashboardWidgetComponent, CountUpStatComponent],
})
export class AnforderungenOffenWidgetComponent {
  @Input() data?: AnforderungenOffenWidgetData;
}
