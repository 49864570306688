import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { ErrorComponent } from '@core/ui';
import { Applikation, ApplikationenService } from '@mp/shared/data-access';

import { RouteBuilder } from './route-builder';

@Injectable({ providedIn: 'root' })
export class RouteBuildService {
  constructor(private readonly appsService: ApplikationenService, private readonly router: Router) {}

  initRoutes(): Observable<Applikation[]> {
    return this.setupAppRoutes();
  }

  private setupAppRoutes(): Observable<Applikation[]> {
    return this.appsService.getAll().pipe(
      tap((apps: Applikation[]) => {
        const appRoutes = this.buildRoutesFromApplications(this.router.config, apps);
        this.router.resetConfig(appRoutes);
      }),
    );
  }

  private buildRoutesFromApplications(initialRouterConfig: Array<Route>, apps: Array<Applikation>): Array<Route> {
    const errorRoute = {
      path: 'error',
      component: ErrorComponent,
      data: {
        errorCode: 404,
        errorMessage: 'Die angegebene Seite konnte nicht gefunden werden.',
      },
    };

    const homeRouteConfig: Route = this.findHomeRoute(initialRouterConfig);
    homeRouteConfig.children = RouteBuilder.fromRouterConfig(homeRouteConfig.children ?? [])
      .registerMultipleApplications(apps)
      .registerErrorRoute(errorRoute)
      .buildRoutes();

    return initialRouterConfig;
  }

  private findHomeRoute(appRoutes: Array<Route>): Route {
    return appRoutes.find((route) => route.path === '') as Route;
  }
}
