import { InjectionToken } from '@angular/core';

import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { BENUTZER_FEATURE_KEY, BenutzerEffects, benutzerReducer } from '@mp/system/benutzer/data-access';
import { ORGANISATIONEN_FEATURE_KEY, OrganisationenEffects, organisationenReducer } from '@mp/system/organisationen/data-access';
import {
  ORGANISATION_ADRESSEN_FEATURE_KEY,
  OrganisationAdressenEffects,
  organisationAdressenReducer
} from '@mp/meine-organisation/adressen/data-access';
import {
  ORGANISATION_BENUTZER_FEATURE_KEY,
  OrganisationBenutzerEffects,
  organisationBenutzerReducer
} from '@mp/meine-organisation/benutzer/data-access';
import { ROLLEN_FEATURE_KEY, RollenEffects, rollenReducer } from '@mp/system/rollen/data-access';
import { ROUTER_FEATURE_KEY } from '@mp/shared/data-access';

export const appGlobalReducers: ActionReducerMap<any> = {
  [ROUTER_FEATURE_KEY]: routerReducer,
  [ROLLEN_FEATURE_KEY]: rollenReducer,
  [BENUTZER_FEATURE_KEY]: benutzerReducer,
  [ORGANISATION_BENUTZER_FEATURE_KEY]: organisationBenutzerReducer,
  [ORGANISATION_ADRESSEN_FEATURE_KEY]: organisationAdressenReducer,
  [ORGANISATIONEN_FEATURE_KEY]: organisationenReducer
};

export const appGlobalEffects = [
  RollenEffects,
  BenutzerEffects,
  OrganisationBenutzerEffects,
  OrganisationAdressenEffects,
  OrganisationenEffects
];

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<any>>('App Reducers');
export const reducerProvider = {
  provide: REDUCERS_TOKEN,
  useValue: appGlobalReducers
};
