import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LetDirective } from '@ngrx/component';
import { Observable, skip, tap } from 'rxjs';

import { UtilPipesModule } from '@core/shared/util';
import { SelectorFlyoutModule } from '@core/ui';
import { OrganisationBasic } from '@mp/shared/kernel/newsfeed/domain';

import { NewsOrganisationsSelectorViewModel } from './news-organisations-selector-view-model';
import { NewsOrganisationsSelectorStore } from './news-organisations-selector.store';

@Component({
  selector: 'mp-news-organisations-selector',
  standalone: true,
  templateUrl: './news-organisations-selector.component.html',
  styleUrls: ['./news-organisations-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    LetDirective,

    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,

    UtilPipesModule,
    SelectorFlyoutModule,
  ],
  providers: [NewsOrganisationsSelectorStore],
})
export class NewsOrganisationsSelectorComponent {
  @HostBinding() readonly class = 'mp-news-organisations-selector';

  @Input() set selectedOrganisations(selectedOrganisations: OrganisationBasic[]) {
    this.store.setSelectedOrganisations(selectedOrganisations);
  }

  @Input() set availableOrganisations(allAvailableOrganisations: OrganisationBasic[]) {
    this.store.setAvailableOrganisations(allAvailableOrganisations);
  }

  @Output() readonly selectedOrganisationsChange: EventEmitter<OrganisationBasic[]> = new EventEmitter<
    OrganisationBasic[]
  >();

  readonly vm$: Observable<NewsOrganisationsSelectorViewModel> = this.store.vm$;

  readonly selectedOrganisations$: Observable<OrganisationBasic[]> = this.store.selectedOrganisations$;

  constructor(private readonly store: NewsOrganisationsSelectorStore) {
    this.selectedOrganisations$
      .pipe(
        skip(1),
        tap((selectedOrganisations) => this.selectedOrganisationsChange.emit(selectedOrganisations)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  promptOrganisationSelectionFlyout(): void {
    this.store.promptOrganisationSelectionFlyout();
  }

  removeOrganisationFromList(organisationId: number): void {
    this.store.removeSelectedOrganisation(organisationId);
  }
}
