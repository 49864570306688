<mp-shell [appTitle]="appTitle$ | async" [showAppLogo]="showAppLogo$ | async" [(isOpened)]="isSideNavOpened">
  <mp-profil-display />

  @if (!isSideNavOpened()) {
    <div class="navigation-menu-items-separator">
      <mat-divider class="navigation-menu-items-separator__divider" />
    </div>
  }

  <div class="navigation-menu-items" [ngClass]="{ 'navigation-menu-items--collapsed': !isSideNavOpened() }">
    <mp-nav-item
      path="/"
      icon="home"
      [matTooltip]="!isSideNavOpened() ? 'Start' : ''"
      matTooltipClass="mp-app-layout__navigation-menu-tooltip"
      matTooltipPosition="right"
    >
      Start
    </mp-nav-item>

    @for (application of applications; track application.id) {
      <mp-nav-item
        [path]="application.route"
        [icon]="application.icon"
        [label]="application.label"
        [isGroup]="application.childApplications.length > 0"
        [matTooltip]="!isSideNavOpened() && application.childApplications.length === 0 ? application.label : null"
        matTooltipClass="mp-app-layout__navigation-menu-tooltip"
        matTooltipPosition="right"
      >
        @for (childApplication of application.childApplications; track childApplication.id) {
          <mp-nav-item
            [path]="childApplication.route"
            [icon]="childApplication.icon"
            [addChildItemLine]="isSideNavOpened() ? true : false"
          >
            {{ childApplication.label }}
          </mp-nav-item>
        }
      </mp-nav-item>
    }
  </div>

  @if (!isSideNavOpened() && hasHelperFunctions) {
    <div class="navigation-menu-items-separator">
      <mat-divider class="navigation-menu-items-separator__divider" />
    </div>
  }

  <mp-helper-links-container
    class="navigation-menu-items__helper-links-container"
    sidebar-end-element
    [isSmall]="!isSideNavOpened()"
    (hasHelperFunctions)="hasHelperFunctions = $event"
  />
</mp-shell>
