import { APP_INITIALIZER, Injectable, Injector } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Observable, catchError, filter, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@core/shared/data-access';
import { AppTheme } from '@core/shared/domain';
import { ThemeHandlerService, redirectToErrorPage } from '@core/shared/util';
import { ConfigLoaderFacade, ConfigLoaderService } from '@mp/shared/data-access';

import { ProfileVerifyService } from './profile-verify.service';
import { RouteBuildService } from './route-build.service';

interface InitialRouteState {
  url: string;
  title: string;
}

@Injectable()
export class AppInitService {
  constructor(private readonly injector: Injector, private readonly configLoaderFacade: ConfigLoaderFacade) {}

  init(): Observable<unknown> {
    return this.configLoaderFacade.authConfig$.pipe(
      switchMap((authConfig) => this.setupAuth(authConfig)),
      switchMap((routerState) => this.setupAppTheme().pipe(map(() => routerState))),
      switchMap((routerState) => this.initializeApp(routerState)),
      catchError(() => {
        redirectToErrorPage();
        throw Error('Error during app initialization, navigating to error page');
      }),
    );
  }

  private setupAuth(authConfig: AuthConfig): Observable<InitialRouteState | undefined> {
    const authService = this.injector.get(AuthService);

    const state: InitialRouteState = {
      url: self.location.href,
      title: document.title,
    };

    return of(authConfig).pipe(
      tap(() => authService.setupUsingOAuthConfiguration(authConfig)),
      // Try login
      switchMap(() => authService.executeCodeFlowWithState(state)),
      // Continue only if user is logged in (executeCodeFlowWithState will not always wait for that)
      switchMap((routerState) => authService.userSuccessfullyLoggedIn$.pipe(map(() => routerState))),
    );
  }

  private initializeApp(routerState: InitialRouteState | undefined): Observable<unknown> {
    const profileVerifyService = this.injector.get(ProfileVerifyService);
    const routeBuildService = this.injector.get(RouteBuildService);

    return of(routerState).pipe(
      switchMap(() => profileVerifyService.verifyProfileConfig()),
      filter(Boolean),
      // Try to init routes only if profile verification was successful - only then we can successfully fetch app routes
      switchMap(() => routeBuildService.initRoutes()),
      tap(() => this.restoreLastVisitedPageFromState(routerState)),
    );
  }

  private setupAppTheme(): Observable<unknown> {
    const themeHandlerFacade = this.injector.get(ThemeHandlerService);

    return this.configLoaderFacade.environmentConfig$.pipe(
      switchMap(({ theme }) => themeHandlerFacade.setTheme(theme as AppTheme)),
    );
  }

  private restoreLastVisitedPageFromState(state: InitialRouteState | undefined): void {
    if (state?.url && state.url !== self.location.href) {
      /**
       * Die URL nach dem Redirect zum IdentityServer wiederherstellen, sodass uns der Router zur ursprünglich gewünschten Seite bringt.
       * Das geschieht hier ohne neuen Eintrag in der History und ohne den Reload des Browsers zu triggern.
       * Direkt den Router zu benutzen funktioniert nicht, da der AppInitializer hier vorm Router-Init läuft.
       */
      self.history.replaceState({}, state.title || document.title, state.url);
    }
  }
}

export const appInitProvider = [
  ConfigLoaderService,
  ConfigLoaderFacade,
  AppInitService,
  {
    provide: APP_INITIALIZER,
    useFactory: (appInitService: AppInitService) => () => appInitService.init(),
    multi: true,
    deps: [AppInitService],
  },
];
