<mp-basic-flyout [title]="'Hilfe Center'" (closed)="close()">
  <div class="flyout-content">
    <div class="flyout-content__articles">
      <ng-container *ngrxLet="zendeskHelpCenterArticles$ as zendeskHelpCenterArticles; suspenseTpl: loading">
        <div class="faq-header">
          <span class="faq-header__title">FAQ</span>

          <span class="faq-header__results-info">
            <ng-container *ngIf="zendeskHelpCenterArticles.length > 0; else noArticlesMessage">
              {{ zendeskHelpCenterArticles.length }} Artikel zu diesem Thema
            </ng-container>

            <ng-template #noArticlesMessage> Keine Artikel zu diesem Thema </ng-template>
          </span>
        </div>

        <mp-zendesk-help-center-articles-list [zendeskHelpCenterArticles]="zendeskHelpCenterArticles" />
      </ng-container>
    </div>

    <div class="flyout-content__actions" *ngIf="helpCenterLink || showCreateTicketButton">
      <mp-zendesk-help-center-flyout-actions [helpCenterLink]="helpCenterLink">
        <ng-container *ngIf="showCreateTicketButton && createTicketButtonTemplate">
          <ng-container *ngTemplateOutlet="createTicketButtonTemplate" />
        </ng-container>
      </mp-zendesk-help-center-flyout-actions>
    </div>
  </div>
</mp-basic-flyout>

<ng-template #loading>
  <mp-spinner />
</ng-template>
