<div class="modification-info">
  <ng-content></ng-content>

  <span class="modification-info__user-name">{{ modifierInfo.firstName }} {{ modifierInfo.lastName }}</span>
  <span
    *ngrxLet="modificationDate | humanizeRelativeDate : { whenInRange: { days: 2 } } as relativeDate"
    class="modification-info__date truncate"
    [title]="relativeDate"
  >
    {{ relativeDate }}
  </span>
</div>
