<mp-dashboard-widget
  widgetTitle="Anforderungen (Historie)"
  icon="fact_check"
  [featurePageLink]="['/medical-shop/auftraege/historie']"
  [enabledByPermission]="true"
>
  <ng-container *ngIf="numbers && value != undefined">
    <p class="subtitle">Dieser Monat ({{ timeNow | date : 'MMMM' : 'DE' }})</p>

    <div class="requisition-statistics">
      <mpcm-count-up-stat
        class="requisition-statistics__approved"
        [statValue]="numbers.anforderungenFreigegebenAnzahl"
        [statLabel]="'Freigegeben'"
        [ngClass]="{
          'mpcm-count-up-stat--success': numbers.anforderungenFreigegebenAnzahl > 0
        }"
      ></mpcm-count-up-stat>

      <mpcm-count-up-stat
        class="requisition-statistics__rejected"
        [statValue]="numbers.anforderungenAbgelehntAnzahl"
        [statLabel]="'Abgelehnt'"
        [ngClass]="{
          'mpcm-count-up-stat--danger': numbers.anforderungenAbgelehntAnzahl > 0
        }"
      ></mpcm-count-up-stat>

      <mpcm-count-up-stat
        class="requisition-statistics__total"
        [statValue]="value"
        [statLabel]="'Auftragswert'"
        [options]="countUpOptionsCurrency"
      ></mpcm-count-up-stat>
    </div>
  </ng-container>
</mp-dashboard-widget>
