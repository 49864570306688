import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse, Pagination } from '@mp/shared/data-access';
import {
  CreateNewsItem,
  ListNewsItem,
  NewsItem,
  OrganisationBasic,
  UpdateNewsItem,
} from '@mp/shared/kernel/newsfeed/domain';

export const NewsfeedActions = createActionGroup({
  source: 'Newsfeed',
  events: {
    'Fetch news list': props<{ pagination?: Partial<Pagination> }>(),
    'Fetch news list success': props<{ newsPageResponse: PageResponse<ListNewsItem> }>(),
    'Fetch news list error': emptyProps(),

    'Fetch available organisations': emptyProps(),
    'Fetch available organisations success': props<{ availableOrganisations: OrganisationBasic[] }>(),
    'Fetch available organisations error': emptyProps(),

    'Create news entry': props<{ createNews: CreateNewsItem }>(),
    'Create news entry success': props<{ createdNews: NewsItem }>(),
    'Create news entry error': emptyProps(),

    'Update news entry': props<{ newsItemId: string; updateNews: UpdateNewsItem }>(),
    'Update news entry success': props<{ updatedNews: NewsItem }>(),
    'Update news entry error': emptyProps(),

    'Delete news entry': props<{ newsItemId: string }>(),
    'Delete news entry success': props<{ newsItemId: string }>(),
    'Delete news entry error': emptyProps(),
  },
});
