<div class="dashboard row">
  <div class="widget-column column">
    <h2>Auf einen Blick</h2>

    <mpcm-content-management-widget
      *ngIf="showContentManagementWidgetRight | rechtIsHeldByUser | async"
      class="widget"
    ></mpcm-content-management-widget>

    <mpcm-datasource-upload-widget
      class="widget"
      *ngIf="showDatasourceUploadWidgetRight | rechtIsHeldByUser | async"
    ></mpcm-datasource-upload-widget>

    <ng-container *ngIf="medicalShopWriteRights | rechtIsHeldByUser | async">
      <ng-container *ngIf="medicalShopWidgetData$ | async as medicalShopWidgetData">
        <mp-medical-shop-widget class="widget" [data]="medicalShopWidgetData"></mp-medical-shop-widget>
      </ng-container>
    </ng-container>

    <mpms-medical-shop-widget
      class="widget"
      *ngIf="showMedicalShopWidgetRight | rechtIsHeldByUser | async"
    ></mpms-medical-shop-widget>

    <ng-container
      *ngIf="
        (readRequisitionsRights | rechtIsHeldByUser | async) || (readAllRequisitionsRights | rechtIsHeldByUser | async)
      "
    >
      <ng-container *ngIf="anforderungenOffenWidgetData$ | async as anforderungenOffenWidgetData">
        <mp-anforderungen-offen-widget class="widget" [data]="anforderungenOffenWidgetData">
        </mp-anforderungen-offen-widget>
      </ng-container>

      <ng-container *ngIf="anforderungenHistorieWidgetData$ | async as anforderungenHistorieWidgetData">
        <ng-container *ngIf="anforderungenGesamtwertData$ | async as anforderungenGesamtwertData">
          <mp-anforderungen-historie-widget
            class="widget"
            [numbers]="anforderungenHistorieWidgetData"
            [value]="anforderungenGesamtwertData"
          >
          </mp-anforderungen-historie-widget>
        </ng-container>
      </ng-container>
    </ng-container>

    <mpdm-pending-requisition-counts-widget
      *ngIf="showPendingRequisitionsWidgetRight | hasRequisitionsWidgetRights | async"
      class="widget"
    >
    </mpdm-pending-requisition-counts-widget>

    <mpdm-requisitions-history-widget
      *ngIf="showRequisitionsHistoryWidgetRight | hasRequisitionsWidgetRights | async"
      class="widget"
    ></mpdm-requisitions-history-widget>

    <ng-container *ngIf="readOrganizationRights | rechtIsHeldByUser | async">
      <ng-container *ngIf="meineOrganisationWidgetData$ | async as meineOrganisationWidgetData">
        <mp-meine-organisation-widget class="widget" [data]="meineOrganisationWidgetData">
        </mp-meine-organisation-widget>
      </ng-container>
    </ng-container>
  </div>

  <div class="news-column column">
    <h2 class="news-column__title">
      <mat-icon svgIcon="mdi:newspaper-variant-outline"></mat-icon>
      Aktuelles
    </h2>

    <mp-newsfeed></mp-newsfeed>
  </div>
</div>
