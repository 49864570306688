import { Component, Input } from '@angular/core';

import { MedicalShopKennzahlenDto } from '@mp/dashboard/data-access';
import { ArticleCountsBaseWidgetComponent } from '@mpcm/shared';

import { DashboardFacade } from '../../dashboard.facade';

@Component({
  selector: 'mp-medical-shop-widget',
  templateUrl: './medical-shop-widget.component.html',
  styleUrls: ['./medical-shop-widget.component.scss'],
  standalone: true,
  imports: [ArticleCountsBaseWidgetComponent],
})
export class MedicalShopWidgetComponent {
  @Input() data!: MedicalShopKennzahlenDto;

  constructor(private readonly facade: DashboardFacade) {}

  handleSearch(searchTerm: string): void {
    this.facade.navigateToShopArtikelsuche(searchTerm);
  }
}
