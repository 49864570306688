import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HelpCenterArticle } from '@mp/shared/zendesk/domain';

import { ZendeskHelpCenterArticleCardComponent } from '../zendesk-help-center-article-card/zendesk-help-center-article-card.component';

@Component({
  selector: 'mp-zendesk-help-center-articles-list',
  standalone: true,
  templateUrl: './zendesk-help-center-articles-list.component.html',
  styleUrls: ['./zendesk-help-center-articles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, ZendeskHelpCenterArticleCardComponent],
})
export class ZendeskHelpCenterArticlesListComponent {
  @Input({ required: true }) zendeskHelpCenterArticles!: HelpCenterArticle[];

  trackByFn(_index: number, { id }: HelpCenterArticle): number {
    return id;
  }
}
