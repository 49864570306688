import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QueryParams } from '@core/shared/util';
import { PageResponse, Pagination } from '@mp/shared/data-access';
import {
  CreateNewsItem,
  ListNewsItem,
  NewsItem,
  OrganisationBasic,
  UpdateNewsItem,
} from '@mp/shared/kernel/newsfeed/domain';

@Injectable()
export class NewsfeedService {
  private readonly baseUrl = '/api/kernel/news';

  constructor(private readonly http: HttpClient) {}

  getNews(pagination?: Partial<Pagination>): Observable<PageResponse<ListNewsItem>> {
    const { page = 1, pageSize = 10 } = pagination || {};
    const params = QueryParams.build().page(page).pageSize(pageSize).toHttpParams();
    return this.http.get<PageResponse<ListNewsItem>>(this.baseUrl, { params });
  }

  getAvailableOrganisations(): Observable<OrganisationBasic[]> {
    return this.http.get<OrganisationBasic[]>(`${this.baseUrl}/available-organizations`);
  }

  addNews(createNewsItem: CreateNewsItem): Observable<NewsItem> {
    return this.http.post<NewsItem>(this.baseUrl, createNewsItem);
  }

  updateNews(newsItemId: string, updateNewsItem: UpdateNewsItem): Observable<NewsItem> {
    return this.http.put<NewsItem>(`${this.baseUrl}/${newsItemId}`, updateNewsItem);
  }

  deleteNews(newsItemId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${newsItemId}`);
  }
}
