import { Injectable, inject } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { BehaviorSubject, Observable, defaultIfEmpty, filter, of, switchMap, tap } from 'rxjs';

import { NotificationService } from '@mp/shared/data-access';

import { ZENDESK_ACCESS_TOKEN_KEY } from '../injection-tokens';

import { ZendeskAuthService } from './zendesk-auth.service';

@Injectable()
export class ZendeskAuthFacade {
  private readonly ZENDESK_ACCESS_TOKEN_STORAGE_KEY: string = inject(ZENDESK_ACCESS_TOKEN_KEY);

  private readonly _zendeskAccessToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    this.getStoredAccessToken() || null,
  );

  readonly zendeskAccessToken$: Observable<string | null> = this._zendeskAccessToken$.asObservable();

  constructor(
    private readonly zendeskAuthService: ZendeskAuthService,
    private readonly notificationService: NotificationService,
  ) {}

  authorizeInZendesk(clientId: string | undefined): Observable<string | null> {
    return of(clientId).pipe(
      tap((zendeskClientId) => zendeskClientId || this.showNoZendeskAccessMessage()),
      filter(Boolean),
      switchMap((zendeskClientId) => this.getZendeskAuthToken(zendeskClientId)),
      defaultIfEmpty(null),
    );
  }

  private getZendeskAuthToken(zendeskClientId: string): Observable<string | null> {
    return this.getAuthToken(zendeskClientId).pipe(
      tapResponse(
        (accessToken) => accessToken || this.showNoZendeskAccessMessage(),
        () => this.showNoZendeskAccessMessage(),
      ),
    );
  }

  private showSuccessfulZendeskAccessMessage(): void {
    this.notificationService.toastSuccess('Die Anmeldung in Zendesk war erfolgreich.');
  }

  private showNoZendeskAccessMessage(): void {
    this.notificationService.toastDanger(
      'Zendesk konnte nicht aufgerufen werden. Versuche es später nochmal oder kontaktiere den Administrator.',
    );
  }

  private getAuthToken(clientId: string): Observable<string | null> {
    const storedAccessToken: string | null = this.getStoredAccessToken();

    if (storedAccessToken) {
      return of(storedAccessToken);
    }

    return this.zendeskAuthService.getAuthToken(clientId).pipe(
      tap((accessToken: string | null) => {
        this._zendeskAccessToken$.next(accessToken);

        if (accessToken) {
          this.showSuccessfulZendeskAccessMessage();
          this.storeAccessToken(accessToken);
        }
      }),
    );
  }

  resetAuthToken(): void {
    sessionStorage.removeItem(this.ZENDESK_ACCESS_TOKEN_STORAGE_KEY);
  }

  private storeAccessToken(accessToken: string): void {
    sessionStorage.setItem(this.ZENDESK_ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }

  private getStoredAccessToken(): string | null {
    return sessionStorage.getItem(this.ZENDESK_ACCESS_TOKEN_STORAGE_KEY);
  }
}
