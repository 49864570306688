<mpcm-article-counts-base-widget
  title="Medical Shop"
  icon="store"
  [featurePageLink]="['/medical-shop/artikelsuche/start']"
  [data]="{
    industryArticleCount: data.industrieArtikelAnzahl,
    clinicArticleCount: data.klinikArtikelAnzahl
  }"
  [enabledByPermission]="true"
  (searchTermChange)="handleSearch($event)"
>
</mpcm-article-counts-base-widget>
