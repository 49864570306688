import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, OnInit, WritableSignal, signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavigationModule, ShellComponent } from '@core/ui';
import { AppConfigService, Applikation, ApplikationenService } from '@mp/shared/data-access';
import { HelperLinksContainerComponent } from '@mp/shared/helper-links/feature';
import { ProfilDisplayComponent, ProfilModule } from '@mp/shared/profil/feature';

// NOTE: Don't add the ChangeDetection.OnPush flag here! This leads to weird behavior of the submenu
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'mp-app-layout',
  standalone: true,
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  imports: [
    NgClass,
    AsyncPipe,

    MatTooltipModule,

    ShellComponent,
    ProfilModule,
    NavigationModule,
    HelperLinksContainerComponent,
    ProfilDisplayComponent,
    MatDividerModule,
  ],
})
export class AppLayoutComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-app-layout';

  applications: Applikation[] = [];
  hasHelperFunctions = false;

  readonly isSideNavOpened: WritableSignal<boolean> = signal<boolean>(false);

  readonly appTitle$: Observable<string> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.appTitle),
  );

  readonly showAppLogo$: Observable<boolean | undefined> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.showAppLogo),
  );

  constructor(
    private readonly applicationsService: ApplikationenService,
    private readonly appConfigService: AppConfigService,
  ) {}

  ngOnInit(): void {
    this.applications = this.applicationsService.applications;
  }
}
