<mpdm-article-counts-widget
  *ngIf="articleCounts$ | async as articleCounts"
  title="Medical Shop"
  icon="store"
  [featurePageLink]="['/medical-shop2/']"
  [data]="articleCounts"
  [enabledByPermission]="true"
  (searchTermChange)="handleSearch($event)"
>
</mpdm-article-counts-widget>
